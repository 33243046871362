import React, {useEffect, useState} from "react";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  withStyles,
  createStyles,
  Paper,
  Button,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteDoctor, getDoctor } from "../../utils/fetcher";

const Bunner = (props) => {

  const [doctors, setDoctors] = useState([]);

  const { classes } = props;

  useEffect(() => {
    fetchBanners();
  }, [])
  
  const fetchBanners = () => getDoctor().then((response) => {
    if (response.status === 200) {
      setDoctors(response.data);
    }
  })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to="/doctor/add"
        >
          Добавить элемент
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="space-between">
          {doctors.map((item) => (
            <Grid key={item.id} item xs={6}>
              <Paper className={classes.banner} elevation={3}>
                <Box p={3}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <IconButton
                        style={{ marginRight: 0 }}
                        edge="end"
                        aria-label="comments"
                        component={Link}
                        to={`/doctor/edit/${item.id}`}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton 
                        onClick={ 
                          () => deleteDoctor(item.id).then(
                            res=> {
                              alert(res.message); 
                              fetchBanners();
                            })
                        } 
                        edge="end" 
                        aria-label="comments" 
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container justify="flex-start" spacing={3}>
                    <Grid item xs={3}>
                      <Avatar
                        className={classes.avatar}
                        src={`data:image/png;base64,${item?.specialist?.photo}`}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" component="span" style={{ color: "#c0c0c0" }}>
                        {item?.specialist?.presentation}
                      </Typography>
                    
                      <Typography variant="body2" component="h6">
                        {item?.specialist?.specialization?.presentation}
                      </Typography>
                      <Typography variant="body1" component="p">
                        {item.title}
                      </Typography>
                      <Typography variant="caption" component="p">
                        {item.url}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    banner: {
      height: 170,
      backgroundColor: "#E1F5FD",
    },
    avatar: {
      width: 64,
      height: 64,
    }
  });

export default withStyles(styles)(Bunner);
