export const cityList = [
  {
    name: "Вологда",
    trimmedId: "38593dd7-ce2c-11e6-81e6-001e679abfc4"
  },
  {
    name: "Череповец",
    trimmedId: "38593dd8-ce2c-11e6-81e6-001e679abfc4"
  },
  {
    name: "Шексна",
    trimmedId: "38593dd9-ce2c-11e6-81e6-001e679abfc4"
  },
  {
    name: "Вельск",
    trimmedId: "38593ddb-ce2c-11e6-81e6-001e679abfc4"
  },
  {
    name: "Котлас",
    trimmedId: "38593ddc-ce2c-11e6-81e6-001e679abfc4"
  },
]