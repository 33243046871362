import React from "react";
import {
  Drawer,
  AppBar,
  Avatar,
  Toolbar,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  withStyles,
  createStyles,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ContactsIcon from "@material-ui/icons/Contacts";
import DoctorIcon from "@material-ui/icons/Person";
// import BonusIcon from '@material-ui/icons/RedeemSharp';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useLocation } from "react-router-dom";
import { logout } from "../../utils/fetcher";

const drawerWidth = 240;

const routes = [
  {
    label: "Баннер",
    path: "/bunner",
    icon: <DashboardIcon />,
  },
  {
    label: "Вопрос-ответ",
    path: "/question",
    icon: <ContactsIcon />,
  },
  {
    label: "Специалисты",
    path: "/doctor",
    icon: <DoctorIcon />,
  },
  // {
  //   label: "Бонусы",
  //   path: "/bonus",
  //   icon: <BonusIcon />,
  // },
];

const routeNames = {
  "/bunner": "Баннеры",
  "/bunner/add": "Добавить баннер",
  "/bunner/edit": "Редактировать баннер",
  "/question": "Вопрос-ответы",
  "/question/add": "Добавить вопрос-ответ",
  "/question/edit": "Редактировать вопрос-ответ",
  "/doctor": "Специалисты",
  "/doctor/add": "Добавить специалиста",
  "/doctor/edit": "Редактировать специалиста",
  "/bonus": "Бонусы",
  "/bonus/edit": "Редактировать бонусный баннер",
};

const Navigation = (props) => {
  const { classes, setAuth } = props;
  const location = useLocation();

  const exit = async () => {
    const response = await logout();
    if (response.status === "success") {
      setAuth({
        success: false,
        name: "",
      });
    }
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            {routeNames[location.pathname] || ""}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List>
          <ListItem button>
            <ListItemIcon>
              <Avatar className={classes.cardHeader} aria-label="Recipe">
                <Typography conponent="div">
                  {props.userName
                    .split(" ")
                    .map((item) => item.slice(0, 1))
                    .join("")}
                </Typography>
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={props.userName} />
          </ListItem>
        </List>
        <Divider />
        <List>
          {routes.map((item) => (
            <ListItem button key={item.label} to={item.path} component={Link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={exit}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Выход" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

const styles = (theme) =>
  createStyles({
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
  });

export default withStyles(styles)(Navigation);
