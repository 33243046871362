import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addDoctor, getDoctors } from "../../../utils/fetcher";

const AddBunner = (props) => {
  const [doctors, setDoctors] = useState([]);
  const [doctor, setDoctor] = useState(null);
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');

  const history = useHistory();

  useEffect(() => {
    getDoctors().then(({data}) => setDoctors(data))
  }, [])

  const saveDoctor = () => {
    addDoctor(doctor?.id, text, url).then(
      res => {
        alert(res.message);
        if (res.status !== "error") {
          history.push('/doctor');
        }
      })
    }


  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Box fontSize="20px" pb={1}>
            Доктор
          </Box>
          <Autocomplete
            options={doctors}
            onChange={(_, value) => {
              console.log(value);
              setDoctor(value);
            }}
            value={doctor}
            noOptionsText="Нет вариантов"
            getOptionLabel={(it) => it.presentation}
            renderOption={(it) => (
              <Typography variant="caption">
                {it.presentation}
              </Typography>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Доктор"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Box fontSize="20px" pb={1}>
            Произвольный текст
          </Box>
          <TextField onChange={e => setText(e.target.value)} value={text} fullWidth variant="outlined" />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Box fontSize="20px" pb={1}>
            Ссылка
          </Box>
          <TextField onChange={e => setUrl(e.target.value)} value={url} fullWidth variant="outlined" />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to="/doctor"
              >
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={saveDoctor}>
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
  });

export default withStyles(styles)(AddBunner);
