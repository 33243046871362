import React, {useEffect, useState} from "react";
import {
  Box,
  Grid,
  Typography,
  withStyles,
  createStyles,
  Paper,
  Button,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MobileIcon from '@material-ui/icons/Smartphone';
import DesktopIcon from '@material-ui/icons/Computer';
import AnyDeviceIcon from '@material-ui/icons/Devices';
import BonusIcon from '@material-ui/icons/RedeemSharp';
import { format } from 'date-fns'
import { deleteBanner, getBanners } from "../../utils/fetcher";

const Bunner = (props) => {

  const [banners, setBanners] = useState([]);

  const { classes } = props;

  useEffect(() => {
    fetchBanners();
  }, [])
  
  const fetchBanners = () => getBanners().then((response) => {
    // console.log(response.data, response.status);
    if (response.status === 200) {
      setBanners(response.data);
    }
  })


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to="/bunner/add"
        >
          Добавить элемент
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="space-between">
          {banners[0] && banners.map((item) => (
            <Grid key={Math.random()} item xs={6}>
              <Paper className={classes.banner} elevation={3} style={{backgroundImage: `url(data:image/png;base64,${item.img})`}}>
                <Box p={3}>
                  <Grid container justifyContent="space-between" style={{background: 'white', opacity: '.8', padding: 6}}>
                    <Grid item xs={12}>
                      <Typography component="p" variant="h5" className={classes.overflowText}>
                        {item.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between" style={{background: 'white', opacity: '.8', padding: 6}}>
                    <Grid item xs={12}>
                      <Typography component="p" variant="body1" className={classes.overflowText}>
                        {item.subtitle}
                      </Typography>
                      <Typography component="p" variant="body2" className={classes.overflowText}>
                        {item.url}
                      </Typography>
                      <Typography component="p" variant="body1">
                        {item.begin ? format(new Date(item.begin), 'dd.MM.yyyy') : null} - {item.end ? format(new Date(item.end), 'dd.MM.yyyy') : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" style={{background: 'white', opacity: '.8', padding: 6}}>
                    <Grid item xs={10}>
                      <Button
                        style={{ marginRight: 2, textTransform: 'none'}}
                        component={Link}
                        to={`/bunner/edit/${item.id}`}
                        color="default"
                        variant="outlined"
                        startIcon={<EditIcon />}
                        size="small"
                      >
                         Изменить
                      </Button>
                      <Button 
                        style={{ marginRight: 2, textTransform: 'none' }}
                        startIcon={<DeleteIcon />}
                        onClick={
                          () => deleteBanner(item.id).then(
                            res=> {
                              alert(res.message); 
                              fetchBanners();
                            })
                        }
                        edge="end"
                        variant="outlined"
                        size="small"
                      >
                        Удалить
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                      {item.isLoyaltyBanner && <IconButton style={{ marginRight: 0 }}><BonusIcon /></IconButton>}
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton style={{ marginRight: 0 }}>
                        {item.format === 'desktop' ? <DesktopIcon /> : item.format === 'mobile' ? <MobileIcon /> : <AnyDeviceIcon/>}
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    banner: {
      height: 210,
      backgroundColor: "#E1F5FD",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top',
      backgroundSize: "650px Auto"
    },
    overflowText: {
      textOverflow: 'ellipsis', 
      overflow: 'hidden', 
      height: 22, 
      whiteSpace: "nowrap"
    }
  });

export default withStyles(styles)(Bunner);
