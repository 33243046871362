import React, { useState, useEffect } from "react";
import {
  Grid,
  withStyles,
  createStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Navigation from "./components/Navigation";
import Question from "./components/Question";
import Bunner from "./components/Bunner";
import Doctor from "./components/Doctor";
import Bonus from "./components/Bonus";
import AddQuestion from "./components/Question/AddQuestion";
import EditQuestion from "./components/Question/EditQuestion";
import AddBunner from "./components/Bunner/AddBunner";
import EditBunner from "./components/Bunner/EditBunner";
import AddDoctor from "./components/Doctor/AddDoctor";
import EditDoctor from "./components/Doctor/EditDoctor";
import EditBonus from "./components/Bonus/EditBonus";

import { SignIn } from "./components/SignIn";
import * as fetcher from "./utils/fetcher";

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#5db8ff",
      main: "#0088ce",
      dark: "#005b9d",
      contrastText: "#ededed",
    },
    secondary: {
      main: "#e51b1b",
      contrastText: "#000000",
    },
    background: {
      default: "rgb(245, 245, 245)",
    },
    text: {
      secondary: "rgba(0, 0, 0, 0.6)",
      secondaryWhite: "rgba(255, 255, 255, 0.6)",
    },
  },
  typography: {
    // Use the system font.
    fontFamily:
      '"Golos", "PT Sans", "Helvetica Neue", "Open Sans","Segoe UI",Roboto,Arial,sans-serif',
  },
  overrides: {
    MuiTab: {
      wrapper: {
        flexDirection: "row",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1920,
    },
  },
});

const App = (props) => {
  const { classes } = props;
  const [auth, setAuth] = useState({
    success: false,
    name: "",
  });
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    setFetched(false);
    fetcher.whoami().then(response => {
      if (
        response.status === "success" &&
        response.data.doctor.group === "doctor"
      ) {
        setAuth({
          success: true,
          name: response.data.doctor.name,
        });
        return null;
      }
    })
    .catch(() => {
      setAuth({
        success: false,
        name: "",
      });
     })
    .finally(() => {
      setFetched(true);
    });
  }, []);

  if (!fetched) {
    return null;
  }

  return (
    <Router>
      <MuiThemeProvider theme={outerTheme}>
        {auth.success ? (
          <Grid className={classes.root}>
            <Navigation userName={auth.name} setAuth={setAuth} />
            <Grid className={classes.content}>
              <Grid className={classes.toolbar} />
              <Switch>
                <Route exact path="/bunner" component={Bunner} />
                <Route exact path="/question" component={Question} />
                <Route exact path="/doctor" component={Doctor} />
                <Route exact path="/bonus" component={Bonus} />
                <Route path="/question/add" component={AddQuestion} />
                <Route path="/question/edit/:recId" component={EditQuestion} />
                <Route path="/bunner/add" component={AddBunner} />
                <Route path="/bunner/edit/:recId" component={EditBunner} />
                <Route path="/doctor/add" component={AddDoctor} />
                <Route path="/doctor/edit/:recId" component={EditDoctor} />
                <Route path="/bonus/edit" component={EditBonus} />
              </Switch>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Redirect to={"/signin"} />
            <Route
              exact
              path="/signin"
              component={() => <SignIn setAuth={setAuth} />}
            />
          </Grid>
        )}
      </MuiThemeProvider>
    </Router>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  });

export default withStyles(styles)(App);
