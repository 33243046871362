import React, { useState } from "react";
import {
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  TextField,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useHistory } from "react-router-dom";
import { addFAQ } from "../../../utils/fetcher";

const AddQuestion = (props) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [order, setOrder] = useState(0);

  const history = useHistory();

  const answerChange = (e, editor) => {
    const data = editor.getData();
    setAnswer(data);
  };

  const saveFAQ = () => {
    addFAQ(question, answer, order).then(
      res => {
        alert(res.message);
        history.push('/question')
      })
    }

  console.log(answer, question)

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Box fontSize="20px" pb={1}>
          Вопрос
        </Box>
        <TextField fullWidth variant="outlined" onChange={e => setQuestion(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <Box fontSize="20px" pb={1}>
          Ответ
        </Box>
        <CKEditor editor={ClassicEditor} onChange={answerChange} />
        <div>{answer}</div>
      </Grid>
      <Grid item xs={12}>
        <Box fontSize="20px" pb={1}>
          Приоритет
        </Box>
        <TextField type="number" fullWidth variant="outlined" onChange={e => setOrder(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/question"
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={saveFAQ}>
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
  });

export default withStyles(styles)(AddQuestion);
