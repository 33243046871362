import React, { useState,useEffect } from "react";
import {
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link, useHistory, useParams, } from "react-router-dom";
import { getDoctor, getDoctors, editDoctor } from "../../../utils/fetcher";

const EditBunner = (props) => {
  const [doctors, setDoctors] = useState([]);
  const [doctor, setDoctor] = useState(null);
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');

  const history = useHistory();
  const { recId } = useParams();

  useEffect(() => {
    getDoctors().then(({data}) => setDoctors(data))
  }, [])

  useEffect(() => {
    if (recId) {
      getDoctor(recId).then(({data}) => {
        console.log(data);
        setDoctor(data[0].specialist);
        setText(data[0].title);
        setUrl(data[0].url);
      })
    }
   }, [recId])

  const saveDoctor = () => {
    editDoctor(recId, doctor?.id, text, url).then(
      res => {
        alert(res.message);
        if (res.status !== "error") {
          history.push('/doctor');
        }
      })
    }

  
  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Box fontSize="20px" pb={1}>
            Доктор
          </Box>
          <Autocomplete
            options={doctors}
            onChange={(_, value) => {
              console.log(value);
              setDoctor(value);
            }}
            value={doctor || null}
            noOptionsText="Нет вариантов"
            getOptionLabel={(it) => it.presentation}
            renderOption={(it) => (
              <Typography variant="caption">
                {it.presentation}
              </Typography>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Доктор"
                variant="outlined"
                fullWidth
              />
            )}
          />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Box fontSize="20px" pb={1}>
            Произвольный текст
          </Box>
          <TextField onChange={e => setText(e.target.value)} value={text} fullWidth variant="outlined" />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Box fontSize="20px" pb={1}>
            Ссылка
          </Box>
          <TextField onChange={e => setUrl(e.target.value)} value={url} fullWidth variant="outlined" />
        </Grid>
      
    </Grid>
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/doctor"
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={saveDoctor}> 
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
  });

export default withStyles(styles)(EditBunner);
