import React, { useState, useEffect } from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  TextField,
  RadioGroup,
  Radio,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { addBanner } from "../../../utils/fetcher";
import { format } from 'date-fns'
import { cityList } from "../constants";

const AddBunner = () => {
  const [cities, setCities] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [url, setUrl] = useState("");
  const [checkedCities, setCheckedCities] = useState([]);
  const [file, setFile] = useState(null);
  const [dateBegin, setDateBegin] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [loyalty, setLoyalty] = useState(false);
  const [form, setForm] = useState("desktop");
  
  const history = useHistory();

  useEffect(() => {
    setCities(cityList);
  }, [])


  useEffect(() => {
    if (loyalty) {
      setForm("desktop");
    } else {
      setForm("any");
    }
  }, [loyalty])

   const onCityCheck = (cityId) => (_, value) => {
    if (value) {
      setCheckedCities([...checkedCities, cityId]);
    } else {
      setCheckedCities(checkedCities.filter(it => it !== cityId));
    }
  } 

  const saveBunner = () => {
    console.log(checkedCities, title, subtitle, url, file, format(dateBegin, 'yyyy-MM-dd'), format(dateEnd, 'yyyy-MM-dd'), loyalty, form)
    addBanner(file, title, subtitle, url, checkedCities, format(dateBegin, 'yyyy-MM-dd'), format(dateEnd, 'yyyy-MM-dd'), loyalty, form).then(
      res => {
        alert(res?.error?.message || res.message);
        history.push('/bunner')
      });
  }

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={2}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={loyalty} onChange={(_, value) => setLoyalty(value)} />}
                label="Бонусный баннер"
                // disabled={cities.length > 0}
              /> 
              <FormControl>
                <FormLabel id="group-label">Формат</FormLabel>
                <RadioGroup
                  aria-labelledby="group-label"
                  defaultValue={form}
                  value={form}
                  onChange={(_, value) => setForm(value)}
                >
                  <FormControlLabel value="desktop" control={<Radio />} label="Desktop" />
                  <FormControlLabel value="mobile" control={<Radio />} label="Mobile" />
                  <FormControlLabel value="any" control={<Radio />} label="Any" />
                </RadioGroup>
              </FormControl>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl component="fieldset">
            <Box fontSize="20px" pb={1}>
              Город
            </Box>
            <FormGroup>
              {cities.map(it => (
                <FormControlLabel
                  key={it.trimmedId}
                  control={<Checkbox checked={checkedCities.includes(it.trimmedId)} onChange={onCityCheck(it.trimmedId)} />}
                  label={it.name}
                  disabled={loyalty}
                /> 
              ))}
            </FormGroup>
            <FormHelperText>Может быть выбрано несколько вариантов</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box fontSize="20px" pb={1}>
              Период действия
            </Box>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <DatePicker 
                  label="Дата начала"
                  inputFormat="dd/MM/yyyy"
                  value={dateBegin}
                  onChange={setDateBegin}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <DatePicker
                  label="Дата окончания"
                  inputFormat="dd/MM/yyyy"
                  value={dateEnd}
                  onChange={setDateEnd}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>              
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Box fontSize="20px" pb={1}>
            Заголовок
          </Box>
          <TextField onChange={e => setTitle(e.target.value)} value={title} variant="outlined" fullWidth />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Box fontSize="20px" pb={1}>
            Подзаголовок
          </Box>
          <TextField onChange={e => setSubTitle(e.target.value)} value={subtitle} variant="outlined" fullWidth />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Box fontSize="20px" pb={1}>
            Ссылка
          </Box>
          <TextField onChange={e => setUrl(e.target.value)} value={url} variant="outlined" fullWidth />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <form method="post" encType="multipart/form-data">
            <input onChange={e => setFile(e.target.files[0])} type="file" id="file" name="file" />
          </form>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to="/bunner"
              >
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={saveBunner} variant="contained" color="primary">
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
  });

export default withStyles(styles)(AddBunner);
