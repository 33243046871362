import React from "react";
import {
  Grid,
  Typography,
  withStyles,
  createStyles,
  Button,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import {bonusDesc, bonusMob} from './constants';

const Bunner = (props) => {

  const { classes } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={8}>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to="/bonus/edit"
        >
          Редактировать
        </Button>
      </Grid>
        <Grid container spacing={2} justifyContent ="space-between">
          <Grid item xs={12}>
            <Grid container justifyContent ="flex-start">
              <Grid item xs={2}><Typography>Баннер десктоп</Typography></Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={`data:image/png;base64,${bonusDesc}`}
                  alt="none"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container justifyContent ="flex-start">
              <Grid item xs={6}><Typography>Баннер мобильный</Typography></Grid>
              <Grid item>
                <img
                  className={classes.img}
                  src={`data:image/png;base64,${bonusMob}`}
                  alt="none"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
          <Grid container justifyContent ="flex-start">
              <Grid item xs={6}><Typography>Информация</Typography></Grid>
              <Grid item>
                <Alert severity="info" className={classes.withMargin}>
                  Оплатите любую услугу медицинского центра Вита (Целди) и вы уже
                  участник программы лояльности, Копите баллы и расплачивайтесь
                  ими в любом медицинском центре сети Вита.
                </Alert>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    banner: {
      minHeight: 170,
      backgroundColor: "#E1F5FD",
    },
    avatar: {
      width: 64,
      height: 64,
    }
  });

export default withStyles(styles)(Bunner);
