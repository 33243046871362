import React, { useEffect, useState } from "react";
import {orderBy} from 'lodash-es';
import {
  Grid,
  Divider,
  withStyles,
  createStyles,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import { deleteFAQ, getFAQ } from "../../utils/fetcher";

const Question = (props) => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchFAQ();
  }, [setQuestions])
  
  const fetchFAQ = () => getFAQ().then((response) => {
    console.log(response.data, response.status);
    if (response.status === 200) {
      setQuestions(response.data);
    }
  })

  console.log(questions)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to="/question/add"
        >
          Добавить элемент
        </Button>
      </Grid>
      <Grid item xs={12}>
        <List>
          <Divider />
          {orderBy(questions, 'order').map((item) => (
            <div key={item.id}>
              <ListItem>
                <ListItemText primary={item.question} secondary={
                  <>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                    
                  />
                  <Typography variant="caption" component="p">Приоритет: {item.order}</Typography>
                  </>
                } />
                <ListItemSecondaryAction>
                  <IconButton
                    style={{ marginRight: 0 }}
                    edge="end"
                    component={Link}
                    to={`/question/edit/${item.id}`}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="comments" onClick={
                    () => deleteFAQ(item.id).then(
                      res=> {
                        alert(res.message); 
                        fetchFAQ();
                      })
                  }>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
  });

export default withStyles(styles)(Question);
