import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  withStyles,
  createStyles,
  Button,
  TextField,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useParams, useHistory } from "react-router-dom";
import { editFAQ, getFAQ } from "../../../utils/fetcher";

const EditQuestion = (props) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [order, setOrder] = useState(0);
  
  const history = useHistory();
  const { recId } = useParams();

  const answerChange = (_, editor) => {
    const data = editor.getData();
    setAnswer(data);
  };

 useEffect(() => {
  if (recId) {
    getFAQ(recId).then(res => {
      setQuestion(res.data[0].question);
      setAnswer(res.data[0].answer);
      setOrder(res.data[0]?.order)
    })
  }
 }, [recId])

const saveFAQ = () => {
  editFAQ (recId, question, answer, order).then(
    res => {
      alert(res.message);
      history.push('/question')
    })
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Box fontSize="20px" pb={1}>
          Вопрос
        </Box>
        <TextField fullWidth variant="outlined" value={question} onChange={e => setQuestion(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <Box fontSize="20px" pb={1}>
          Ответ
        </Box>
        <CKEditor editor={ClassicEditor} data={answer} onChange={answerChange} />
      </Grid>
      <Grid item xs={12}>
        <Box fontSize="20px" pb={1}>
          Приоритет
        </Box>
        <TextField type="number" value={order} fullWidth variant="outlined" onChange={e => setOrder(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/question"
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={saveFAQ} >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
  });

export default withStyles(styles)(EditQuestion);
